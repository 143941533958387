
import { initializeApp } from "firebase/app";
import { getFirestore} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import 'firebase/compat/auth'; //v9
import firebase from 'firebase/compat/app';
import { getFunctions } from 'firebase/functions';
import { getStorage } from "firebase/storage";
import { getPerformance } from "firebase/performance";


const firebaseConfig = {
  apiKey: "AIzaSyBnXSkink7HI3GQXhyNalQXM2DF0VolT_o",
  authDomain: "trainonlineia.firebaseapp.com",
  databaseURL: "https://wanderline-c2eb5-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "trainonlineia",
  storageBucket: "trainonlineia.appspot.com",
  messagingSenderId: "249166620499",
  appId: "1:249166620499:web:b536433d1e9acf908ae3a6",
  measurementId: "G-9XF5QVYMLL",
};


if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);

  
}

// console.log("loggin", user, user.uid);
//const analytics = getAnalytics(app);

const app = initializeApp(firebaseConfig);

const storage = getStorage(app);

const db = getFirestore(app);


// app.settings({
//   experimentalForceLongPolling: true,
// })

const Auth = getAuth();

const functions = getFunctions(app);
const perf = getPerformance(app);

export { db, storage, Auth, firebase, functions, perf };


 



