//import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import  secureLocalStorage  from  "react-secure-storage";
//import {getDoc, doc} from "firebase/firestore";
import { query, getDocs, collection, orderBy, updateDoc, doc, serverTimestamp} from "firebase/firestore";
import { db } from '../../../components/firebase/Firebase';
//const API_URL = '/api/data/ticket/TicketData';

const initialState = {
  tickets: [],
  currentFilter: 'total_tickets',
  ticketSearch: '',
};

export const TicketSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    getTickets: (state, action) => {
      state.tickets = action.payload;
    },
    setVisibilityFilter: (state, action) => {
      state.currentFilter = action.payload;
    },
    SearchTicket: (state, action) => {
      state.ticketSearch = action.payload;
    },
    DeleteTicket: (state, action) => {

      console.log("monde", state, action);
   
     // const index = state.tickets.findIndex((ticket) => ticket.id === action.payload);
     // console.log("il faut valider le ticket : ", index.toString(), action.payload);
      //state.tickets.splice(index, 1);
      const superhero = async () => {
        await updateDoc(doc(db, secureLocalStorage.getItem('path_matieres_global'), "tickets", action.payload.toString()), {
          DateVal : serverTimestamp(),
          Status : "Closed", 
          Label : "danger"
          });
          window.location.reload(false);
      }
      superhero();
      //
    
    },
  },
});

export const { getTickets, setVisibilityFilter, SearchTicket, DeleteTicket } = TicketSlice.actions;

export const fetchTickets = () => async (dispatch) => {
  
 //   const response = await axios.get(`${API_URL}`);
 //   dispatch(getTickets(response.data));
 const DemandesRef = collection(db, secureLocalStorage.getItem('path_matieres_global'), 'tickets');
 const q = query(DemandesRef, orderBy("Date", "desc"));
 const ReponseDemandesRef = await getDocs(q);
 const objetF = ReponseDemandesRef.docs.map((docu) => ({ ...docu.data(), id: docu.id }))
 console.log("Ouhouh :", objetF);
  const temp = []
 objetF.forEach(element => {
  console.log("bizut", element);
  let date = 0
  let dateVal = 0
  try {
    date = element.Date.seconds
  } catch (e) {
    console.log(e)
  }
  try {
    dateVal = element.DateVal.seconds
  } catch (e) {
    console.log(e)
  }

  const temp2 = {
    id: element.id, 
    Date: date, 
    DateVal: dateVal, 
    Eleve: element.Eleve, 
    Label: element.Label, 
    Status: element.Status, 
    ticketDescription: element.ticketDescription, 
    ticketTitle: element.ticketTitle, 
    Severite: element.Severite, 
    Sujet: element.Sujet
  }

  temp.push(temp2);
  console.log("jamais", temp2);
	

});

console.log("carro : ", temp);

 dispatch(getTickets(temp))
  
};

export default TicketSlice.reducer;
