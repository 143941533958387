import { createContext, useEffect, useReducer } from 'react';
import  secureLocalStorage  from  "react-secure-storage";
import PropTypes from 'prop-types';
import {getDoc, doc, getDocs, collection} from "firebase/firestore";
import { firebase, db } from './Firebase';




const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'AUTH_STATE_CHANGED') {
    const { isAuthenticated, user } = action.payload;
    console.log("bidibile");
    


    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  platform: 'Firebase',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  onceGetUsers: () => Promise.resolve(),
  CreateUser: () => Promise.resolve(),
});



export const AuthProvider = (props) => {

  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {

        
        if (user) {
          // Here you should extract the complete user profile to make it available in your entire app.
          // The auth state only provides basic information.
          console.log("dededede1")

          //const { email } = user.email;
          // eslint-disable-next-line no-unused-vars
          const temp = user.uid;
          // eslint-disable-next-line no-unused-vars
          const magnin = "Ci4XLZzKcSMkMyjBAyZCwyspaO63";
          // eslint-disable-next-line no-unused-vars
          const serrurot = "KhKsVnSV12PBrxiTuynsDs8gqBf1";
          // eslint-disable-next-line no-unused-vars
          const notterman = "sRQkq2Z9JxcjNkoKPSk5JJtL4J33";
          // eslint-disable-next-line no-unused-vars
          const zaborny = "M7wdL1YEPGWQkxoNNcJpL9y6Xu33";
          // eslint-disable-next-line no-unused-vars
          const lallouni = "bvcR7H21dQaQBXC2KlwaAxQe3jI3";
           // eslint-disable-next-line no-unused-vars
          const cloarec = "I2himk7IWFMh5uX2GvIBgjnuiV43";
           // eslint-disable-next-line no-unused-vars
          const boisot = "H709SeCoX1PLUaqhjitUnczfpL52"
           // eslint-disable-next-line no-unused-vars
          const yahya = "U9tzzMw3sdZefSDeFoClEaJIxVL2";
           // eslint-disable-next-line no-unused-vars
          const jardot = "So8vTaIDrHdYsXCQsm26SjIj7IC3";
          // eslint-disable-next-line no-unused-vars
          const herman = "fGv8Dp6CnTa544EPDmA5QsMOkpr2";
          // eslint-disable-next-line no-unused-vars
          const anaelle = "xZPDBb1ffyZY1nUZm4nUIfKhTFn1";
          // eslint-disable-next-line no-unused-vars
          const ranguis = "YoYnvudsttaXmjw5a0EjPqiRgyv1";
          // eslint-disable-next-line no-unused-vars
          const bernard = "xhEsdAIcOefDOK4qPcCahLmo8f32";
          // eslint-disable-next-line no-unused-vars
          const cupillard = "KKsUm65XNecALIpESnHC9L0M9KH3";
  // eslint-disable-next-line no-unused-vars
          const mercier = "ZYkJdNZuSjMx43ArQ5478ZHY6Cm2";
          // eslint-disable-next-line no-unused-vars
          const bonavent = "7b1ZLEwLwhdfCDx07w97I7ihUL53"
            // eslint-disable-next-line no-unused-vars
          const kherbach = "nV9B5XNpLHTTMtIkwhQFxZBxSqE2"
            // eslint-disable-next-line no-unused-vars
          const broutin = "f8DKUk6X9mT1iz4EZfcUil08uU83"
            // eslint-disable-next-line no-unused-vars
          const bernardino = "atnbFITal8hKEAFMpNciq9iihCN2"
// eslint-disable-next-line no-unused-vars
          const lime = "8hOUW0K89UXLHXWGcGIEvyMC5CI3"
          // eslint-disable-next-line no-unused-vars
          const morquin = "DV5ZRdID7pYMTbzKmUzd7HzLfN22"
          // eslint-disable-next-line no-unused-vars
          const paillard = "i1t7SByf5tVgyTth5oZCOfrXOFt1"
          // eslint-disable-next-line no-unused-vars
          const perney = "olZPiyBlBdYiQZmBUPW0f4gRlwm2"
          // eslint-disable-next-line no-unused-vars
          const amar = "paA5kXqM0qYxwRLkm2QfqtfN11I3"
          // eslint-disable-next-line no-unused-vars
          const triqueneaux = "CVsNb4RZ5fbiTc8bmc9mAoeQT1u2"
          // eslint-disable-next-line no-unused-vars
          const thiebaud = "nuz6qUw1IycXbJJ7ysRrUiKEbmw1"
          // eslint-disable-next-line no-unused-vars
          const haller = "AXIzAA6iUmd9aRGSdxiFIaSEWja2"

      
          
          
          secureLocalStorage.setItem('CurrentUser',  zaborny );
          secureLocalStorage.setItem('CurrentUsermail', user.email);


          
          const superhero2 = async () => {

          const docEleveUID = await getDoc(doc(db, "UID", secureLocalStorage.getItem("CurrentUser")));
          secureLocalStorage.setItem('role', docEleveUID.data().role);
          secureLocalStorage.setItem('etablissement', docEleveUID.data().etablissement);
          secureLocalStorage.setItem('classe', docEleveUID.data().classe);
         

          let droitsT = 
          {
            access: false,
            ajout_activites: false,
            consultation_validations: false,
            edition_activites: false,
            edition_parcours: false,
            validation_validations: false
          }
       
          if (docEleveUID.data().role === "teacher"){  
             
           console.log("bichonet")
         droitsT = (await getDoc(doc(db, "etablissements", secureLocalStorage.getItem('etablissement'), "professeurs", secureLocalStorage.getItem("CurrentUser"), "classes", secureLocalStorage.getItem("classe"), "matieres",secureLocalStorage.getItem("CurrentSubject") ))).data();
         console.log("filip : ", "etablissements", secureLocalStorage.getItem('etablissement'), "professeurs", secureLocalStorage.getItem("CurrentUser"), "classes", secureLocalStorage.getItem("classe"), "matieres",secureLocalStorage.getItem("CurrentSubject"), droitsT)
        // setDroits(droitsT)
         }
         console.log("prime : ", droitsT)
         secureLocalStorage.setItem('droits', droitsT);



          console.log("hotage", `etablissements, ${docEleveUID.data().etablissement}, classes, ${docEleveUID.data().classe}, matieres`)

          const qmatieres = await getDocs(collection(db, "etablissements", docEleveUID.data().etablissement, "sections", "BTS", "denominations", "SNIR", "classes", docEleveUID.data().classe, "matieres"));
          const obj2 = [];
          qmatieres.forEach((doca) => {
          // doc.data() is never undefined for query doc snapshots
          console.log("valerie", doca.data());
          obj2.push(doca.data());
          });
 
      console.log("crier :",  obj2);
      secureLocalStorage.setItem('list', JSON.stringify(obj2));
      console.log("crier2 :",  JSON.parse(secureLocalStorage.getItem('list')));

      console.log("moi :", `etablissements/${docEleveUID.data().etablissement}/classes/${secureLocalStorage.getItem("classe")}`)

      secureLocalStorage.setItem('path', `etablissements/${docEleveUID.data().etablissement}/sections/BTS/denominations/SNIR/classes/${secureLocalStorage.getItem("classe")}`);
        }
    
        superhero2().catch(console.error);
        
        console.log("badabule");
    
      
         

         
          
         
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: true,
              user: {
                id: user.uid,
                avatar: user.photoURL,
                email: user.email,
              },
            },
          });
        } else {
          console.log("dededede2")
          secureLocalStorage.setItem('state', {uid: "bidon"});
          secureLocalStorage.setItem('CurrentUsermail', "dede@dede.com");
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
       
      }),
      
    [dispatch],
   
  );

  // Sign Up
  const createUserWithEmailAndPassword = (email, password) =>
    firebase.auth().createUserWithEmailAndPassword(email, password);
  // Sign In
  const signInWithEmailAndPassword = (email, password) =>
    firebase.auth().signInWithEmailAndPassword(email, password);
    console.log("dededede22")

  // Sign out
  const logout = () => firebase.auth().signOut();
  const CreateUser = (id, username, email) =>
    firebase.database().ref(`users/${id}`).set({
      username,
      email,
    });
  const onceGetUsers = () => firebase.database().ref('users').once('value');

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Firebase',
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        CreateUser,
        onceGetUsers,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};


AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};


export default AuthContext;
