import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
import AuthGuard from '../components/authGurad/AuthGuard';

/****Layouts*****/
const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));

/***** Pages ****/

const Starter = Loadable(lazy(() => import('../views/Starter')));
const StarterOld = Loadable(lazy(() => import('../views/StarterOld')));
const Agenda = Loadable(lazy(() => import('../views/ui/Agenda')));
const Soumettre = lazy(() => import("../views/ui/Soumettre"));
const Validation = lazy(() => import("../views/ui/Validation"));
const Suivi = lazy(() => import("../views/ui/Suivi"));
//const Agenda = lazy(() => import("../views/ui/Agenga"));
const ParcoursAll = lazy(() => import("../views/ui/ParcoursAll"));
const ActivitesAll = lazy(() => import("../views/ui/ActivitesAll"));
const EntrainementE4 = lazy(() => import("../views/ui/EntrainementE4"));
const Ressources = lazy(() => import("../views/ui/Ressources"));
const ParcoursDetail = lazy(() => import("../views/ui/ParcoursDetail"));
const DocsUtiles = lazy(() => import("../views/ui/DocsUtiles"));
const FAQ = lazy(() => import("../views/ui/FAQ"));
const DonneesPerso = lazy(() => import("../views/ui/DonneesPerso"));

// const Parcours1 = lazy(() => import("../views/ui/Parcours1"));
// const Parcours2 = lazy(() => import("../views/ui/Parcours2"));
// const Parcours3 = lazy(() => import("../views/ui/Parcours3"));
// const Parcours4 = lazy(() => import("../views/ui/Parcours4"));
// const Parcours5 = lazy(() => import("../views/ui/Parcours5"));
// const Parcours6 = lazy(() => import("../views/ui/Parcours6"));
// const Parcours7 = lazy(() => import("../views/ui/Parcours7"));
// const Parcours8 = lazy(() => import("../views/ui/Parcours8"));
// const Parcours9 = lazy(() => import("../views/ui/Parcours9"));

// const Parcours1 = lazy(() => import("../views/ui/Parcours1"));
// const Parcours2 = lazy(() => import("../views/ui/Parcours2"));
// const Parcours3 = lazy(() => import("../views/ui/Parcours3"));
// const Parcours4 = lazy(() => import("../views/ui/Parcours4"));
// const Parcours5 = lazy(() => import("../views/ui/Parcours5"));
// const Parcours6 = lazy(() => import("../views/ui/Parcours6"));
// const Parcours7 = lazy(() => import("../views/ui/Parcours7"));
// const Parcours8 = lazy(() => import("../views/ui/Parcours8"));
// const Parcours9 = lazy(() => import("../views/ui/Parcours9"));

const FormSteps = Loadable(lazy(() => import('../views/form-steps/Steps')));
const ParcoursEditor = Loadable(lazy(() => import('../views/form-editor/ParcoursEditor')));
const ActiviteEditor = Loadable(lazy(() => import('../views/form-editor/ActiviteEditor')));

const TicketList = Loadable(lazy(() => import('../views/apps/ticket/TicketList')));
const TicketDetail = Loadable(lazy(() => import('../views/apps/ticket/TicketDetail')));



const DetailCarte = Loadable(lazy(() => import('../views/ui/DetailCarte')));
const About = Loadable(lazy(() => import('../views/About')));
const Alerts = Loadable(lazy(() => import('../views/ui/Alerts')));
const Badges = Loadable(lazy(() => import('../views/ui/Badges')));
const Buttons = Loadable(lazy(() => import('../views/ui/Buttons')));
const Cards = Loadable(lazy(() => import('../views/ui/Cards')));
const Grid = Loadable(lazy(() => import('../views/ui/Grid')));
const Tables = Loadable(lazy(() => import('../views/ui/Tables')));
const Forms = Loadable(lazy(() => import('../views/ui/Forms')));
const Breadcrumbs = Loadable(lazy(() => import('../views/ui/Breadcrumbs')));

/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/Error')));
const Register = Loadable(lazy(() => import('../views/auth/Register')));
const Login = Loadable(lazy(() => import('../views/auth/Login')));
/*****Routes******/



const ThemeRoutes = [
  
  {
    path: '/',
    element: (
      <AuthGuard>
        <FullLayout />
      </AuthGuard>
    ),
    children: [
      { path: '/', element: <Navigate to="/starter" /> },
      { path: '/starter', exact: true, element: <Starter /> },
      { path: '/starterOld', exact: true, element: <StarterOld /> },
      { path: '/form-steps', name: 'form-steps', exact: true, element: <FormSteps /> },
      { path: '/docsutiles', name: 'docsutiles', exact: true, element: <DocsUtiles /> },
      { path: '/faq', name: 'faq', exact: true, element: <FAQ /> },
      
      // { path: '/parcours1', name: 'email', exact: true, element: <Parcours1 /> },
      // { path: '/parcours2', name: 'email', exact: true, element: <Parcours2 /> },
      // { path: '/parcours3', name: 'email', exact: true, element: <Parcours3 /> },
      // { path: '/parcours4', name: 'email', exact: true, element: <Parcours4 /> },
      // { path: '/parcours5', name: 'email', exact: true, element: <Parcours5 /> },
      // { path: '/parcours6', name: 'email', exact: true, element: <Parcours6 /> },
      // { path: '/parcours7', name: 'email', exact: true, element: <Parcours7 /> },
      // { path: '/parcours8', name: 'email', exact: true, element: <Parcours8 /> },
      // { path: '/parcours9', name: 'email', exact: true, element: <Parcours9 /> },

      { path: '/detailcarte', name: 'email', exact: true, element: <DetailCarte /> },
      { path: '/donneesperso', name: 'email', exact: true, element: <DonneesPerso /> },

      { path: '/tickt/ticket-list', name: 'ticket list', exact: true, element: <TicketList /> },
      {
        path: '/tickt/ticket-detail',
        name: 'ticket detail',
        exact: true,
        element: <TicketDetail />,
      },
     
      
      { path: '/parcours-editor', name: 'form-editor', exact: true, element: <ParcoursEditor /> },
      { path: '/activite-editor', name: 'form-editor', exact: true, element: <ActiviteEditor /> },
      { path: '/soumettre', exact: true, element: <Soumettre /> },
      { path: '/validation', exact: true, element: <Validation /> },
      { path: '/entrainementE4', exact: true, element: <EntrainementE4 /> },
      { path: '/suivi', exact: true, element: <Suivi /> },
      { path: '/agenda', exact: true, element: <Agenda /> },
      { path: '/parcoursall', exact: true, element: <ParcoursAll /> },
      { path: '/activitesall', exact: true, element: <ActivitesAll /> },
      { path: '/ressources', exact: true, element: <Ressources /> },
      { path: '/parcoursdetail', exact: true, element: <ParcoursDetail /> },
      // { path: '/parcours1', exact: true, element: <Parcours1 /> },
      // { path: '/parcours2', exact: true, element: <Parcours2 /> },
      // { path: '/parcours3', exact: true, element: <Parcours3 /> },
      // { path: '/parcours4', exact: true, element: <Parcours4 /> },
      // { path: '/parcours5', exact: true, element: <Parcours5 /> },
      // { path: '/parcours6', exact: true, element: <Parcours6 /> },
      // { path: '/parcours7', exact: true, element: <Parcours7 /> },
      // { path: '/parcours8', exact: true, element: <Parcours8 /> },
      // { path: '/parcours9', exact: true, element: <Parcours9 /> },
      
      { path: '/about', exact: true, element: <About /> },
      { path: '/ui/alerts', exact: true, element: <Alerts /> },
      { path: '/badges', exact: true, element: <Badges /> },
      { path: '/buttons', exact: true, element: <Buttons /> },
      { path: '/cards', exact: true, element: <Cards /> },
      { path: '/grid', exact: true, element: <Grid /> },
      { path: '/table', exact: true, element: <Tables /> },
      { path: '/forms', exact: true, element: <Forms /> },
      { path: '/breadcrumbs', exact: true, element: <Breadcrumbs /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: 'auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: 'register', element: <Register /> },
      { path: 'login', element: <Login /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default ThemeRoutes;
